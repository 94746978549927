import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../Layout";
import Hero from "../../components/Hero";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import AccentHeading from "../../components/AccentBar/AccentHeading";
import GreyBox from "../../components/GreyBoxLeft";
import ProcessCard from "../../components/ProcessCard";
import diagram from "../../images/pages/services/maturitymodel/diagram2.svg";
import CTABox from "../../components/CTABox";
import IntroBlock from "../../components/IntroBlock";
import { Link } from "gatsby";

const MaturityModelPage = () => {
  return (
    <Layout>
      <SEO title="CC&S Maturity Model" />

      <Hero imageName="mmBackground">
        <div style={{ width: "50%" }}>
          <h1 className="whiteText servicesHeading">CC&S Maturity Model</h1>
        </div>
      </Hero>

      <section>
        <IntroBlock>
          <Breadcrumb>
            <Breadcrumb.Item as={Link} to="/services/">
              Services
            </Breadcrumb.Item>
            <Breadcrumb.Item>CC&S Maturity Model</Breadcrumb.Item>
          </Breadcrumb>
          <h1>CC&S Maturity Model</h1>
          <p>
            Organisations have been on the road to Climate Change &
            Sustainability (CC&S) maturity for quite some time now. New
            regulation and compliance continually arise, causing maturity model
            to be adopted. The CC&S Maturity Model (CCSMM) is developed to help
            organisations identify where they want to be and which factors need
            to be overcome to ensure that they get there…
          </p>
        </IntroBlock>
      </section>

      <Col
        xs={12}
        sm={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={diagram} style={{ margin: "auto auto", width: '85%' }} />
      </Col>

      <section style={{ marginTop: 100 }}>
        <Container fluid>
          <Row style={{ display: "flex" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <ProcessCard
                number="1"
                title="Common Language"
                text="CC&S initiatives are often ad hoc, unplanned and even chaotic. few process are defines, and success depends on individual effort. In summary, the organisation lacks the capability to meet commitments consistently."
              />
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <ProcessCard
                number="2"
                title="COMMON PROCESS"
                text="CC&S processes established necessary to repeat earlier successes. The focus is on developing and capabilities of the organisation, small central CC&S group ( usually part of Health & Safety or Corporate affairs functions), CC&S not yet part of planning process."
              />
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <ProcessCard
                number="3"
                title="Singular Methodology"
                text="Central CC&S strategy and planning process, towards a centralised model ( Integrated CC&S with core business competences) best practices integrated into a common CC&S process, deployed across the organisation."
              />
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <ProcessCard
                number="4"
                title="Benchmark"
                text="Enterprise orientation, CC&S part of department plans and review cycle, profit and loss structured, department competence centres ( and across the product lifecycle: R&D,Marketing, Operations, Purchasing etc.) detailed performance measures of the CC&S process and outputs collected, quantitatively understood and controlled."
              />
            </Col>

            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <ProcessCard
                number="5"
                title="Continuous Improvement"
                text="CC&S focused on customer needs (voice of the customer), part of corporate strategy, towards decentralised CC&S model. Continuous evaluation of e-business improvements enabled by quantitative feedback, piloting innovative ideas and technologies."
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default MaturityModelPage;
